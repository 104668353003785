import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { format } from 'date-fns'

import ButtonSubmit from '../../common/ButtonSubmit'

const DialogUnsubscribe = ({
  handleClose,
  handleSubmit,
  submitting,
  subscription,
  visible,
  serviceFeeSubsidyOnNextPaymentDate,
}) => {
  return (
    <Dialog maxWidth="sm" onClose={handleClose} open={visible}>
      <DialogTitle>Are you sure you want to unsubscribe from Foodsby Unlimited?</DialogTitle>
      {!serviceFeeSubsidyOnNextPaymentDate && (
        <DialogContent>
          <DialogContentText>
            You will continue to have a $0 service fee until{' '}
            {format(subscription?.nextPaymentDate, 'MM/DD/YYYY')}. After that, you’ll be paying
            $1.99 for every delivery.
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          No, nevermind
        </Button>
        <ButtonSubmit
          onClick={handleSubmit}
          submitting={submitting}
          variant="contained"
          color="primary"
        >
          Yes, unsubscribe
        </ButtonSubmit>
      </DialogActions>
    </Dialog>
  )
}

export default DialogUnsubscribe
