import { getServiceFeeSubsidiesForCurrentUser } from '../../services/serviceFeeSubsidy'
import { createAsyncAction, FULFILLED, PENDING, REJECTED } from '../utils'

const ERROR_MESSAGE_LOAD_SERVICE_FEE_SUBSIDY =
  'There was a problem loading your Unlimited Delivery information. Please refresh the page and try again.'

// ------------------------------------
// Action Types & Creators
// ------------------------------------

export const SERVICE_FEE_SUBSIDY_SET = 'foodsby/serviceFeeSubsidy/SERVICE_FEE_SUBSIDY_SET'

export const setServiceFeeSubsidy = createAsyncAction(SERVICE_FEE_SUBSIDY_SET)

// ------------------------------------
// Thunks
// ------------------------------------
export const loadServiceFeeSubsidy = (activeForDate = new Date()) => dispatch => {
  return dispatch(setServiceFeeSubsidy(getServiceFeeSubsidiesForCurrentUser(activeForDate)))
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [PENDING(SERVICE_FEE_SUBSIDY_SET)]: state => ({
    ...state,
    isLoadingServiceFeeSubsidy: true,
    errorLoadingServiceFeeSubsidy: undefined,
  }),
  [FULFILLED(SERVICE_FEE_SUBSIDY_SET)]: (state, action) => ({
    ...state,
    // Users can only be on one service fee subsidy at a time
    serviceFeeSubsidy: action.payload.length > 0 ? action.payload[0] : null,
    isLoadingServiceFeeSubsidy: false,
    errorLoadingServiceFeeSubsidy: undefined,
  }),
  [REJECTED(SERVICE_FEE_SUBSIDY_SET)]: state => ({
    ...state,
    isLoadingServiceFeeSubsidy: false,
    errorLoadingServiceFeeSubsidy: ERROR_MESSAGE_LOAD_SERVICE_FEE_SUBSIDY,
  }),
}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  isLoadingServiceFeeSubsidy: false,
  serviceFeeSubsidy: null,
  errorLoadingServiceFeeSubsidy: undefined,
}

export default function serviceFeeSubsidy(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
