import { format } from 'date-fns'
import { api } from '../api/api'

export const getServiceFeeSubsidiesForCurrentUser = activeForDate =>
  api.get(
    `/api/v1/accounts/service-fee-subsidy/user?activeForDate=${format(
      activeForDate,
      'YYYY-MM-DD',
    )}`,
  )
